.yell-button {
  --color: #f6f8ff;
  --background: #3ad57f;
  --background-hover: #34bd72;
  --background-disabled: #cfd2d7;
  --shadow: #{rgba(#00093d, 0.12)};
  --person: #f6f8ff;
  --person-arm: var(--person);
  --person-leg: #f6f8ff;
  --weight: #275efe;
  --weight-disk: #5c86ff;
  --person-y: 0;
  --weight-y: 0;
  --arm-rotate: 40;
  --arm-rotate-s: -40;
  --arm-rotate-s-x: 0;
  --leg-y: -2;
  --leg-rotate: 45;
  --leg-rotate-s: -70;
  display: table;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  position: relative;
  cursor: pointer;
  line-height: 24px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  -webkit-tap-highlight-color: transparent;
  margin-left: auto;
  margin-right: auto;
  .inner {
    padding: 14px 20px;
    transition: transform 0.2s, background 0.4s;
    color: var(--color);
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1;
    border-radius: 100px;
    background: var(--b, var(--background));
    box-shadow: 0 1px 3px var(--shadow), 0 3px 7px var(--shadow);
    transform: scale(var(--scale, 1)) translateZ(0);
    &:hover {
      --b: var(--background-hover);
    }
    &.active {
      --b: var(--background-hover);
      animation: vibration 0.1s infinite;
    }
    &.disabled {
      --b: var(--background-disabled);
    }
  }
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    display: block;
    position: relative;
    .person,
    .weight {
      position: absolute;
    }
    .person {
      top: 7px;
      left: 9px;
      width: 6px;
      height: 10px;
      transform: translateY(calc(var(--person-y) * 1px));
      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 0;
      }
      &:before {
        top: -5px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: var(--person);
        transform: scale(0.7);
        transform-origin: 50% 25%;
      }
      &:after {
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        border-radius: 2px 2px 3px 3px;
        background: var(--person);
      }
      .arm,
      .leg {
        position: absolute;
        left: var(--left, 0);
        top: var(--top, 0);
        width: 2px;
        height: 7px;
        border-radius: 1px;
        transform-origin: 1px 1px;
        background: var(--background);
        transform: translate(calc(var(--x, 0) * 1px), calc(var(--y, 0) * 1px))
          rotateZ(calc(var(--rotate, 0) * 1deg));
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 5px;
          width: 2px;
          height: 7px;
          border-radius: 1px;
          transform-origin: 1px 1px;
          background: inherit;
          transform: rotateZ(calc(var(--rotate-s, 0) * 1deg))
            rotateX(calc(var(--rotate-s-x, 0) * 1deg));
        }
        &.right {
          --left: 4px;
          transform: translate(
              calc(var(--x, 0) * -1px),
              calc(var(--y, 0) * 1px)
            )
            rotateZ(calc(var(--rotate, 0) * -1deg));
          &:before {
            transform: rotateZ(calc(var(--rotate-s, 0) * -1deg))
              rotateX(calc(var(--rotate-s-x, 0) * 1deg));
          }
        }
      }
      .arm {
        --background: var(--person-arm);
        --rotate: var(--arm-rotate);
        --rotate-s: var(--arm-rotate-s);
        --rotate-s-x: var(--arm-rotate-s-x);
        z-index: 1;
      }
      .leg {
        --top: 8px;
        --background: var(--person-leg);
        --y: var(--leg-y);
        --rotate: var(--leg-rotate);
        --rotate-s: var(--leg-rotate-s);
      }
    }
    .weight {
      top: 17px;
      left: 0;
      width: 24px;
      height: 2px;
      border-radius: 1px;
      background: var(--weight);
      transform: translateY(calc(var(--weight-y, 0) * 1px));
      &:before,
      &:after {
        content: '';
        position: absolute;
        border-radius: 1px;
        left: var(--l, 1px);
        top: var(--t, -2px);
        width: var(--w, 2px);
        height: var(--h, 6px);
        background: var(--weight-disk);
        box-shadow: var(--bx, 20px) 0 0 var(--weight-disk);
      }
      &:after {
        --l: 3px;
        --t: -3px;
        --h: 8px;
        --bx: 16px;
      }
    }
  }
  .text {
    position: relative;
    span {
      display: block;
      white-space: nowrap;
    }
  }
  .ripple {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: inherit;
    pointer-events: none;
    &:before {
      content: '';
      position: absolute;
      top: calc(var(--ripple-y, 0) * 1px);
      left: calc(var(--ripple-x, 0) * 1px);
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
      width: 200%;
      padding-bottom: 200%;
      border-radius: 50%;
      background: currentColor;
      animation: ripple 0.5s ease-in;
    }
  }
  i {
    position: absolute;
    display: block;
    width: 4px;
    height: 4px;
    top: 50%;
    left: 50%;
    margin: -2px 0 0 -2px;
    opacity: var(--o, 0);
    background: var(--b);
    transform: translate(var(--x), var(--y)) scale(var(--s, 1));
  }
  &.complete {
    .text {
      span {
        &:first-child {
          --o: 0;
          --x: -8px;
        }
        &:last-child {
          --o: 1;
          --x: 0;
        }
      }
    }
    &.confetti {
      i {
        animation: confetti 0.6s ease-out forwards;
      }
    }
  }
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
  &.isLoading {
    pointer-events: none;
  }
}

@keyframes confetti {
  from {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes ripple {
  25% {
    opacity: 0.07;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes vibration {
  0% {
    transform: translate(-1px, 0px) scale(0.98);
  }
  50% {
    transform: translate(1px, 0px) scale(0.98);
  }
  100% {
    transform: translate(-1px, 0px) scale(0.98);
  }
}
