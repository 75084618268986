.react-calendar {
  width: 100% !important;
  border: none !important;

  .react-calendar__month-view__weekdays__weekday {
    abbr {
      text-decoration: none !important;
      font-size: 11px;
      color: #9ca1ab;
      font-weight: medium;
    }
  }

  .react-calendar__month-view__days__day {
    color: #9ca1ab;

    abbr {
      font-size: 16px;
      font-weight: medium;
    }
  }

  .react-calendar__month-view__days__day--weekend {
    color: #9ca1ab;
  }

  .react-calendar__tile--active:enabled:hover {
    background: none;
  }

  .react-calendar__tile--active {
    background: none;

    abbr {
      background-color: #020202;
      color: #ffffff;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .react-calendar__tile--now {
    background: none;
  }
}
