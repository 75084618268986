.maplibregl-ctrl-top-right {
  left: 24px !important;
  top: 24px !important;
  right: auto !important;

  .maplibregl-ctrl {
    margin: 0 !important;

    button {
      width: 32px;
      height: 32px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
    }
  }
}
